/* print styles */

@import "base/settings";
@import "base/mixins.scss";
@import "utils/functions";
@import "base/fonts.scss";

// images
@import "layout/images";

nav,
.footer,
footer { display: none; }

.container { width: 100%; }


